import React from 'react';
import { Link } from '@thd-olt-component-react/router';
import { Helmet } from 'react-helmet-async';

export const Home = () => {
  return (
    <div className="grid isBound u--padding">
      <Helmet>
        <title>Home Page</title>
        <link rel="canonical" href="https://www.homedepot.com/" />
      </Helmet>
      <ul>
        <li><a href="/">Home</a></li>
        <li><Link to="/b/Tools-Power-Tools-Drills/N-5yc1vZc27f">Browse-Drills</Link></li>
        <li><Link to="/p/305723471">Drill (cross experience)</Link></li>
        <li><Link to="/pep/305723471">Drill (pep)</Link></li>
        <li><Link to="/p/304217938">Sofa (in-experience)</Link></li>
        <li><a href="/s/hammer">Search-Hammer</a></li>
      </ul>
    </div>
  );
};

Home.propTypes = {};

Home.defaultProps = {};
